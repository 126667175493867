import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import styled, { css, createGlobalStyle } from 'styled-components'
import Swiper from 'react-id-swiper'
import { useMount, useMedia } from 'react-use'
import tw from 'tailwind.macro'

import { isClient } from '../../utils'
import { media } from '../../styles/utils'
import { container, padding, hoverState } from '../../styles/global'

const Slider = props => {
    const { slides } = props
    const [activeIndex, updateActiveIndex] = useState(null)
    const [swiper, updateSwiper] = useState(null)
    const canLoop = slides && slides.length > 1
    const isMobile = typeof window !== 'undefined' && useMedia('(max-width: 768px)') ? true : false

    const swiperParams = {
        getSwiper: updateSwiper,
        loop: canLoop,
        effect: false,
        speed: 0,
        noSwiping: true,
    }

    useMount(() => {
        onSlideChange()
    })

    useEffect(() => {        
        if (!swiper || !isMobile) return
        
        const timer = setTimeout(() => {
            swiper.slideNext()
        }, 3000);

        return () => clearTimeout(timer);   
    }, [activeIndex]);

    useEffect(() => {
        if (swiper) {
            swiper.on('slideChange', onSlideChange)
            props.onSliderMount && props.onSliderMount(swiper)

            let nextNav = document.querySelectorAll('#nextSlide')
            nextNav.forEach(item => {
                item.addEventListener("click", nextSlide)
            })
            let prevNav = document.querySelectorAll('#prevSlide')
            prevNav.forEach(item => {
                item.addEventListener("click", prevSlide)
            })
        }

        return () => {
            let nextNav = document.querySelectorAll('#nextSlide')
            nextNav.forEach(item => {
                item.removeEventListener("click", nextSlide)
            })
            let prevNav = document.querySelectorAll('#prevSlide')
            prevNav.forEach(item => {
                item.removeEventListener("click", prevSlide)
            })
        }
    }, [swiper])

    useEffect(() => {
        if (!props.activeSlide || !swiper) return
        swiper.slideTo(props.activeSlide, 1000)
    }, [props.activeSlide])

    const onSlideChange = () => {
        updateActiveIndex(swiper ? swiper.realIndex : 0)
    }

    const nextSlide = () => {
        if (!swiper) return
        swiper.slideNext()
    }

    const prevSlide = () => {
        if (!swiper) return
        swiper.slidePrev()
    }

    return (
        <>
            <SwiperStyles />
            <Swiper
                {...swiperParams}
            >
                {props.renderSlides && props.renderSlides(slides)}
            </Swiper>
        </>
    )
}


const SwiperStyles = createGlobalStyle`
    .swiper-container {
        position: relative;
        width: 100%;
        height: 100%;
    }
`

export default Slider
