import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../../styles/utils'
import { parseACF, renderLink } from '../../utils'

const Grid = props => {
    const data = parseACF(useStaticQuery(query), 'allWordpressGlobal')
    const { col_1, col_2 } = props

    const renderLinks = () => {
        const items = data.link.map((item, i) => {
            if (item.link_type === 'link' && item.link.type === 'internal') {
                return (
                    <a key={i} as={Link} to={`${item.link.url}`}>
                        {item.link_text}
                    </a>
                )
            } else {
                return renderLink(item, i)
            }
        })
        return <Links>{items}</Links>
    }

    return (
        <Wrapper>
            <Column>
                {col_1 && col_1}
            </Column>
            <Column>
                <Top>{col_2 && col_2}</Top>
                <Bottom>{data && data.link && renderLinks()}</Bottom>
            </Column>
            <Column>
                <NavItem as={Link} to={'/'}>
                    Back
                </NavItem>
            </Column>
        </Wrapper>
    )
}

const Top = styled.div``
const Bottom = styled.div``
const Links = styled.div``
const NavItem = styled.div``
const Column = styled.div``

const Wrapper = styled.div`
    ${tw`flex`};

    ${media.tabPor`
        ${tw`block`};
    `}

    ${Column} {
        ${tw`flex flex-col p-2`};
        min-height: 100vh;

        ${media.tabPor`
            min-height: initial;
        `}
    }

    ${Column}:first-child {
        ${tw`pr-10`};
    }
    ${Column}:nth-child(2) {
        ${tw`pl-10`};
    }

    ${Column}:first-child,
    ${Column}:nth-child(2) {
        width: 45%;

        ${media.tabPor`
            ${tw`w-full px-2`};
        `}
    }

    ${Column}:last-child {
        width: 10%;
        ${tw`flex flex-col items-end`};

        ${media.tabPor`
            ${tw`block w-full`};
        `}

        a {
            text-decoration: none;
        }
    }

    ${Bottom} {
        ${tw`mt-auto`};

        ${Links} {
            ${tw`flex flex-col`};

            ${media.tabPor`
                ${tw`py-16`};
            `}

            a {
                text-decoration: none;
            }
        }
    }
`

export const query = graphql`
    query {
        allWordpressGlobal {
            nodes {
                acf_json
            }
        }
    }
`


export default Grid
