import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { Layout, Grid } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'
import { parseACF } from '../utils'

const Information = props => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressPages')
    console.log('data', data)

    const renderCol = data => {
        return (
            <div
                className={'info-col'}
                dangerouslySetInnerHTML={{
                    __html: data,
                }}
            />
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Grid
                col_1={renderCol(data.left_column_text)}
                col_2={renderCol(data.right_column_text)}
            />
        </Layout>
    )
}

export const query = graphql`
    query {
        allWordpressPages(filter: { slug: { eq: "marrickville-flowers-studio" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Information
