import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'

import { Layout, Slider } from '../components'
import { media } from '../styles/utils'
import { container, padding, bgImage } from '../styles/global'
import { parseACF, getGlobalData } from '../utils'
import Div100vh from 'react-div-100vh'
import 'swiper/css/swiper.css'

const Index = props => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressPages')
    const globalData = getGlobalData()
    // console.log('data', data)
    // console.log(globalData)

    const renderSlides = slides => {
        return slides.map((item, i) => {
            if (!item.image) return

            return (
                <Slide key={i} image_type={item.image_type}>
                    {renderTop()}

                    <ImageWrapper>
                        <Image src={item.image.sizes.full_width} />
                    </ImageWrapper>

                    {item.caption && (
                        <Bottom>
                            <Container>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: `${item.caption} ${i+1}/${slides.length}`,
                                    }}
                                />
                            </Container>
                        </Bottom>
                    )}

                    <SlideNav>
                        <PrevButton id={'prevSlide'} />
                        <NextButton id={'nextSlide'} />
                    </SlideNav>
                </Slide>
            )
        })
    }

    const renderSlider = slider => {
        return (
            <Slider
                slides={slider}
                renderSlides={renderSlides}
            />
        )
    }

    const renderTop = () => {
        return (
            <Top>
                <Container>
                    <Left>
                        <span>Muck Floral</span> is Sophie Wolanski, an artist whose medium is flowers.
                    </Left>
                    <Right>
                        <NavItem
                            to={`/order-flowers`}
                        >
                            Order Flowers
                        </NavItem>
                        <NavItem
                            to={`/marrickville-flowers-studio`}
                        >
                            Information
                        </NavItem>
                        { globalData.global.display_shop_link && (
                            <ExternalLink
                                href={globalData.global.shop_link_url}
                                target={'_blank'}
                            >
                                {globalData.global.shop_link_text ? globalData.global.shop_link_text : 'Shop'}
                            </ExternalLink>
                        )}
                    </Right>
                </Container>
            </Top>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                {data.slider && renderSlider(data.slider)}
            </Wrapper>
        </Layout>
    )
}

const Wrapper = styled(Div100vh)`
    ${tw`w-full`};
`
const Container = styled.div``

// Top

const ExternalLink = styled.a`
    ${tw`font-shop`};
    font-size: 16px;

    ${media.mob`
        font-size: 15px;
    `}
`
const NavItem = styled(Link)``
const Left = styled.div``
const Right = styled.div``
const Top = styled.div`
    ${tw`relative z-50`};

    ${Container} {
        ${tw`flex justify-between w-full p-2`};

        ${media.tabPor`
            ${tw`block`};
        `}
    }

    ${Left} {
        span {
            text-decoration: underline;
        }
    }

    ${Right} {
        ${tw`flex flex-col items-end`};

        ${media.tabPor`
            ${tw`flex-row items-start mt-2 -mx-1`};
        `}

        ${media.mob`
            ${tw`flex-wrap`};
        `}

        a {
            text-decoration: none;

            ${media.tabPor`
                ${tw`px-1`};
                text-decoration: underline;
            `}
        }
    }
`

// Slide

const ImageWrapper = styled.div``
const Image = styled.img``
const Text = styled.div``

const Slide = styled.div`
    ${tw`relative flex flex-col justify-between w-full h-full bg-white py-0`};

    ${ImageWrapper} {
        ${tw`relative w-full`};
        flex-grow: 1;

        ${props => {
            if (props.image_type !== 'landscape')
                return css`
                    ${tw`absolute inset-0`};

                    ${media.tabPor`
                        position: relative;
                    `}
                `
        }}

        ${Image} {
            ${tw`absolute inset-0 w-full h-full object-contain`};

            ${props => {
                if (props.image_type === 'landscape')
                    return css`
                        width: 66vw;
                        left: 50%;
                        transform: translateX(-50%);

                        ${media.tabPor`
                            ${tw`w-full left-0`};
                            transform: none;
                        `}
                    `

                if (props.image_type === 'fullscreen')
                    return css`
                        ${tw`object-cover`};

                        ${media.tabPor`
                            ${tw`object-contain`};
                        `}
                    `
            }}


            ${media.tabPor`
                ${tw`px-2`};
            `}
        }
    }
`

const Bottom = styled.div`
    ${tw`relative z-50`};

    ${Container} {
        ${tw`p-2`};
    }
`


const NextButton = styled.div``
const PrevButton = styled.div``

const SlideNav = styled.div`
    ${tw`z-10 absolute inset-0 flex`};
    ${NextButton},
    ${PrevButton} {
        ${tw`w-1/2`};
        outline: 0 !important;
    }
`

export const query = graphql`
    query {
        allWordpressPages(filter: { slug: { eq: "home" } }) {
            nodes {
                acf_json
            }
        }
        allWordpressGlobal {
            nodes {
                acf_json
            }
        }
    }
`

export default Index
