import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import Form from 'react-dynamic-form'
import tw from 'tailwind.macro'
import { useMount, useSetState } from 'react-use'
import { postData } from '../services/api'
import { orderFlowers } from '../data/order-flowers'

import { Layout, Grid, Input } from '../components'
import { media } from '../styles/utils'
import { container, padding } from '../styles/global'
import { parseACF } from '../utils'

const OrderFlowers = props => {
    const data = props.previewData || parseACF(useStaticQuery(query), 'allWordpressPages')
    console.log('data', data)

    const contactFormRef = useRef(null)
    const [formState, updateForm] = useSetState({
        submitting: false,
        formComplete: false, // set to true for testing
        error: false,
    })

    const handleSubmit = (fields, resetForm, updateForm) => {
        const { formComplete } = fields

        const data = {
            name: fields.name.value,
            email: fields.email.value,
            flowers: fields.flowers.value,
            vase: fields.vase.value,
            delivery_name: fields.deliveryName.value,
            delivery_date: fields.deliveryDate.value,
            delivery_address: fields.deliveryAddress.value,
            phone: fields.phone.value,
            note: fields.note.value,
            details: fields.details.value,
        }

        updateForm({
            submitting: true,
        })

        postData('/pda/contact', data)
            .then(res => {
                console.log(res)
                if (res.success) {
                    updateForm({
                        formComplete: true,
                        submitting: false,
                    })
                } else {
                    updateForm({
                        error: res.errors[0],
                        submitting: false,
                    })
                }

                resetForm()
            })
            .catch(error => {
                updateForm({
                    error: `Sorry, there's been an error while submitting the form. Please contact us directly at <a href="mailto:flowers@muck.co.nz">flowers@muck.co.nz</a> and we'll get back to you as soon as possible.`,
                    submitting: false,
                })
                resetForm()
            })
    }

    const renderContent = () => {
        const { formComplete } = formState

        return (
            <Content>
                {!formComplete && data.text_before_form && (
                    <Info
                        dangerouslySetInnerHTML={{
                            __html: data.text_before_form,
                        }}
                    />
                )}
                {formComplete && data.thanks_you_message && (
                    <Info
                        dangerouslySetInnerHTML={{
                            __html: data.thanks_you_message,
                        }}
                    />
                )}

                {/* Form */}
                {renderForm()}
            </Content>
        )
    }

    const renderForm = () => {
        const { formComplete, error } = formState
        const flowerOptions = data?.flower_options
        const vaseOptions = data?.vase_options

        return (
            <>
                {!formComplete && (
                    <FormWrapper>
                        <Form
                            ref={contactFormRef}
                            data={orderFlowers(flowerOptions, vaseOptions)}
                            renderInput={<Input />}
                            moveToInvalidField={false}
                            styles={formStyles}
                            renderSubmit={false}
                            onSubmit={(fields, resetForm) =>
                                handleSubmit(fields, resetForm, updateForm)
                            }
                        />

                        {data.text_after_form && (
                            <Info
                                dangerouslySetInnerHTML={{
                                    __html: data.text_after_form,
                                }}
                            />
                        )}

                        <SubmitWrap>
                            {/* {formState.submitting && (
                                <Spinner
                                    name="double-bounce"
                                    color={`black`}
                                />
                            )} */}

                            <Button
                                disabled={formState.submitting ? true : false}
                                onClick={() => {
                                    contactFormRef.current &&
                                        contactFormRef.current.handleSubmit()
                                }}
                            >
                                <span>Send order</span>
                            </Button>
                        </SubmitWrap>
                    </FormWrapper>
                )}

                {!formComplete && error && (
                    <Error dangerouslySetInnerHTML={{ __html: error }} />
                )}
            </>
        )
    }

    return (
        <Layout meta={data && data.seo}>
            <Grid col_1={renderContent()} />
        </Layout>
    )
}

// Shared

const Info = styled.div``
const Button = styled.div`
    ${props => {
        if (props.disabled)
            return css`
                pointer-events: none;
                opacity: 0.5 !important;
            `
    }}
`

// Form

const SubmitWrap = styled.div``
const FormWrapper = styled.div``
const Error = styled.div``
const Content = styled.div`
    ${Info} {
        &, * {
        }

        a {
            text-decoration: underline;
        }
    }

    ${FormWrapper} {
        &, * {
            cursor: initial!important;
        }
    }

    ${SubmitWrap} {
        ${tw`flex justify-start items-center w-full mt-4`};
    }

    ${Button} {
        &, * {
            cursor: pointer!important;
        }
        span {
            text-decoration: underline;
        }
    }

    ${Error} {
        ${tw`mt-4`};
        color: red;
    }
`

const formStyles = css`
    .dynamic-fields {
        ${tw`mb-4`};
    }

    .dynamic-field {
        ${tw`mt-4`};
    }

    /* field validation error */
    .error {
        ${tw`mt-2`};
    }
`

export const query = graphql`
    query {
        allWordpressPages(filter: { slug: { eq: "order-flowers" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default OrderFlowers
