import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled, { css } from 'styled-components'
import Transition from '../../transition'
import tw from 'tailwind.macro'

import { MetaTags } from '../'
import { media } from '../../styles/utils';
import cursor from '../../assets/images/cursor.png'

const Layout = ({ children, meta }) => {
	return (
		<>
			<MetaTags
				{...meta}
			/>
			<Wrapper>
				<Transition>
					{children}
				</Transition>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div`
	${tw`relative w-full`};

	&, *:not(a) {
		cursor: url(${cursor}) 64 32, auto;
	}
`

export default Layout
