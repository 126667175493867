import React, { Component } from 'react'
import { required, phone, email } from '../utils/validators'
import { useMount } from 'react-use'
import arrow  from '../assets/images/select-arrow.svg'

class Select extends Component {
    onChange = e => {
        const { onChangeValue, validator } = this.props
        const value = e.target.value

        if (onChangeValue) {
            onChangeValue(value, validator)
        }
    }

    renderOptions = options => {
        if (!options) return null
        return options.map((item, i) => {
            return (
                <option key={i} value={item.option}>
                    {item.option}
                </option>
            )
        })
    }

    render() {
        const { placeholder, options } = this.props
        return (
            <div className={'select-wrapper'}>
                <select onChange={this.onChange}>
                    <option value="" disabled selected>{placeholder}</option>
                    {this.renderOptions(options)}
                </select>
                <img src={arrow}/>
            </div>
        )
    }
}

export const orderFlowers = (flowerOptions, vaseOptions) => {
    return {
        fields: [
            {
                type: 'text',
                name: 'name',
                placeholder: 'Name',
                validator: required,
                errorMessage: 'This field is required',
            },
            {
                type: 'email',
                name: 'email',
                placeholder: 'Email Address',
                validator: email,
                errorMessage: 'Please enter a valid email address',
            },
            {
                type: 'select',
                name: 'flowers',
                placeholder: 'Flowers',
                validator: required,
                component: ({ value, placeholder, onChangeValue, validator }) => {

                    return (
                        <Select
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={required}
                            options={flowerOptions}
                        />
                    )
                },
            },
            {
                type: 'select',
                name: 'vase',
                placeholder: 'Add a vase',
                validator: required,
                component: ({ value, placeholder, onChangeValue, validator }) => {

                    return (
                        <Select
                            value={value}
                            placeholder={placeholder}
                            onChangeValue={onChangeValue}
                            validator={validator}
                            options={vaseOptions}
                        />
                    )
                },
            },
            {
                type: 'text',
                name: 'deliveryName',
                placeholder: 'Delivery Name',
                validator: required,
                errorMessage: 'This field is required',
            },
            {
                type: 'text',
                name: 'deliveryDate',
                validator: required,
                placeholder: 'Date of delivery or pick up',
            },
            {
                type: 'text',
                name: 'deliveryAddress',
                validator: required,
                placeholder: 'Delivery Address',
            },
            {
                type: 'text',
                name: 'phone',
                placeholder: 'Contact no.',
                validator: phone,
                errorMessage: 'Please enter a valid phone number',
            },
            {
                type: 'textarea',
                name: 'note',
                placeholder: 'Note on card',
            },
            {
                type: 'textarea',
                name: 'details',
                placeholder: 'Style details, or tell me a bit about the recipient',
            },
        ],
    }
}
